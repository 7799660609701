export enum IdPrefix {
  BR = 'BR-',
  EX = 'EX-',
  TY = 'TY-',
  //Not Specified
  NS = ''
}

export enum ReservedId {
  //Thread
  General = "general",
  //Chat
  Private = "private",
  Group = "group",
}
//Ideas for ids
//IN- Internal (is this ideal)
//EX- External (is this ideal)
//RE- Reserved
