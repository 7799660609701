import { GenericUtils } from "./generic-utils";

export const Utils = {
  inIframe(): boolean {
    return this.isIframedWindow(window);
  },

  inSameOriginIframe(): boolean {
    return this.isIframedWindowSameOriginAsParent(window);
  },

  isIframedWindow(win: Window) {
    try {
      //This is allowed even cross origin.
      return win.location !== win.parent.location;
    } catch (error) {
      return false;
    }
  },

  isIframedWindowSameOriginAsParent(win: Window): boolean {
    try {
      //This is not allowed cross origin.
      if (win.location.origin == win.parent.location.origin)
        return true;
      return win.location.origin == 'null' && win.location.href == 'about:srcdoc';
    } catch (error) {
      return false;
    }
  },

  isObjectEmtpy(obj: any): boolean {
    if (!obj) return true;
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (obj[key] !== undefined) {
          return false;
        }
      }
    }
    return true;
  },

  getQueryParamObjectFromString(path: string): any {
    try {
      const params = this.getQueryParamsFromString(path);
      const obj: any = {};
      for (const key of params.keys()) {
        obj[key] = params.get(key);
      }
      return obj;
    } catch (error) {
      return {};
    }
  },

  /**
   *
   * @param path any string that may contain a query string
   * @returns a URLSearchParams object with the query string parameters all lowercased
   */
  getQueryParamsFromString(path: string): URLSearchParams {
    try {
      const parts = path.split('?');
      const query = parts.length > 1 ? parts[1] : '';
      const params = new URLSearchParams(query.toLowerCase());
      return params;
    } catch (error) {
      return new URLSearchParams();
    }
  },


  getQueryParams(): URLSearchParams {
    try {
      return new URLSearchParams(window.location.search);
    } catch (error) {
      return new URLSearchParams();
    }
  },

  getQueryParam(key: string): string | null {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(key);
    } catch (error) {
      return null;
    }
  },

  getLocalStorage(key: string) {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      return null;
    }
  },

  setLocalStorage(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch (error) {

    }
  },

  removeLocalStorage(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (error) {

    }
  },

  getSessionStorage(key: string) {
    try {
      return sessionStorage.getItem(key);
    } catch (error) {
      return null;
    }
  },

  setSessionStorage(key: string, value: string) {
    try {
      sessionStorage.setItem(key, value);
    } catch (error) {

    }
  },

  removeSessionStorage(key: string) {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {

    }
  },

  getSessionId(): string {
    let sessionId = this.getSessionStorage('sessionId');
    if (!sessionId) {
      sessionId = GenericUtils.uuidv4Code();
      this.setSessionStorage('sessionId', sessionId);
    }
    return sessionId;
  },

  deleteCookie(cname: string) {
    if (cname == null || cname == "") {
      cname = "id";
    }
    this.setCookie(cname, "", -1);
  },

  setCookie(cname: string, cvalue: string, exdays: number, ignoreSameSite: boolean = false) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; Path=/;" + expires + (ignoreSameSite ? ' SameSite=None; Secure' : '');
  },

  getCookie(cname: string): string | null {
    const nameEQ = cname + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ')
        c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0)
        return c.substring(nameEQ.length, c.length);
    }
    return null;
  },

  makeCode(): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charactersLength = characters.length;
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  },

  filter<T>(items: T[] | undefined, prop: string, val: any): T[] {
    if (!items?.length) return [];
    const filtered = [];
    for (const user of items) {
      if ((user as any)[prop] == val) filtered.push(user);
    }
    return filtered;
  },

  sort<T>(value: T[], prop: string, desc: boolean, sortType: string, then?: string, thenDesc?: boolean, thenSortType?: string): T[] {
    if (value.length == 0) return value;
    return value.slice().sort((a: any, b: any) => {
      if (sortType == 'date') {
        const dateSortResult = this.dateSort(a[prop], b[prop], desc);
        if (dateSortResult != 0) return dateSortResult;
      }
      else {
        if (a[prop] > b[prop]) return desc ? -1 : 1;
        if (a[prop] < b[prop]) return desc ? 1 : -1;
      }
      if (then) {
        if (thenSortType == 'date') {
          const dateSortResult = this.dateSort(a[then], b[then], desc);
          if (dateSortResult != 0) return dateSortResult;
        }
        else {
          if (a[then] > b[then]) return thenDesc ? -1 : 1;
          if (a[then] < b[then]) return thenDesc ? 1 : -1;
        }
      }


      return 0;
    });
  },

  dateSort(a: any, b: any, desc: boolean): number {
    a = a ? new Date(a) : new Date(-8640000000000000);
    b = b ? new Date(b) : new Date(-8640000000000000);
    if (a.getTime() > b.getTime()) return desc ? -1 : 1;
    if (a.getTime() < b.getTime()) return desc ? 1 : -1;
    return 0;
  },

  convertDataAttribute(attribute: string): string {
    return attribute
      .replace(/^data-/, "")
      .replace(/-./g, x => x[1].toUpperCase());
  },

  /**
 * @hidden
 * @param url
 * @returns
 */
  get<T>(url: string): Promise<T | undefined> {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(async response => {
        try {
          const data: T = await response.json();
          resolve(data);
        } catch (error) {
          console.error(error);
          reject(error)
        }
      }).catch(err => {
        console.error(err);
        reject(err)
      })
    })

  },

  /**
   * @hidden
   * @param url
   * @param data
   * @returns
   */
  post<T>(url: string, data: any): Promise<T | undefined> {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        credentials: 'include'
      }).then(async response => {
        try {
          const data: T = await response.json();
          resolve(data);
        } catch (error) {
          console.error(error);
          reject(error)
        }
      }).catch(err => {
        console.error(err);
        reject(err)
      })
    })

  },

  /**
   * @hidden
   * @param url
   * @param data
   * @returns
   */
  postForm<T>(url: string, data: any): Promise<T | undefined> {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          // 'Content-Type': 'application/json'
        },
        body: formData
      }).then(async response => {
        try {
          const data: T = await response.json();
          resolve(data);
        } catch (error) {
          console.error(error);
          reject(error)
        }
      }).catch(err => {
        console.error(err);
        reject(err)
      })
    })
  },

  getWindowSize(): { width: number, height: number } {
    return {
      width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    }
  },

  positionInWindow(xDirection: "left" | "right" | "center" = "center", yDirection: "top" | "bottom" | "center" = "top", elemPos: DOMRect, width: number, height: number, viewportBuffer: number = 10): { top: string, left: string } {
    // if xDirection is left and it wont fit on the left, but will fit on the right, change xDirection to right
    if (xDirection === "left" && elemPos.left - width - viewportBuffer < 0
      && elemPos.right + width + viewportBuffer <= window.innerWidth) xDirection = "right";

    // if xDirection is right and it wont fit on the right, change xDirection to left
    if (xDirection === "right" && elemPos.right + width + viewportBuffer > window.innerWidth
      && elemPos.left - width - viewportBuffer >= 0) xDirection = "left";

    // if yDirection is top and it wont fit on the top, but will fit on the bottom, change yDirection to bottom
    if (yDirection === "top" && elemPos.top - height - viewportBuffer < 0
      && elemPos.bottom + height + viewportBuffer <= window.innerHeight) yDirection = "bottom";

    // if yDirection is bottom and it wont fit on the bottom, change yDirection to top
    if (yDirection === "bottom" && elemPos.bottom + height + viewportBuffer > window.innerHeight
      && elemPos.top - height - viewportBuffer >= 0) yDirection = "top";


    let t: number, l: number;
    if (yDirection === "top") {
      t = elemPos.top - height;
      t = Math.max(t, viewportBuffer); // Don't go off the top of the screen
    } else if (yDirection === "bottom") {
      t = elemPos.bottom;
      t = Math.min(t, window.innerHeight - height - viewportBuffer); // Don't go off the bottom of the screen
    } else {
      t = elemPos.top + (elemPos.height * 0.5) - (height * 0.5);
      t = Math.max(t, viewportBuffer); // Don't go off the top of the screen
      t = Math.min(t, window.innerHeight - height - viewportBuffer); // Don't go off the bottom of the screen
    }

    if (xDirection === "left") {
      l = elemPos.left - width;
      l = Math.max(l, viewportBuffer); // Don't go off the left of the screen
    } else if (xDirection === "right") {
      l = elemPos.right;
      l = Math.min(l, window.innerWidth - width - viewportBuffer); // Don't go off the right of the screen
    } else {
      l = elemPos.left + (elemPos.width * 0.5) - (width * 0.5);
      l = Math.max(l, viewportBuffer); // Don't go off the left of the screen
      l = Math.min(l, window.innerWidth - width - viewportBuffer); // Don't go off the right of the screen
    }

    return {
      top: `${t}px`,
      left: `${l}px`,
    }
  }
}
