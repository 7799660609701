import { convert } from "html-to-text";
import { IdPrefix } from "./prefixes";
import { v4 } from 'uuid';
import { emojis, emojisByAliases } from "../../bridges/src/app/components/message-box/plugins/emoji-data";

export type Traceparent = { version: string, traceId: string, parentId: string, traceFlags: string };
export type Tracestate = { key: string, value: string }[];

//These utils should be able to be used client side and server side.
export const GenericUtils = {
  getPlainText(body?: string): string {
    return convert(this.replaceEmojis(body) || '');
  },
  replaceEmojis(body?: string): string | undefined {
    const isBrowser = typeof window !== 'undefined';
    if (body && isBrowser) {
      const container = document.createElement("div");
      container.innerHTML = body;
      container.querySelectorAll("[data-type='emoji']").forEach((node: Element) => {
        if (!(node instanceof HTMLElement)) return;
        const emojiAlias = node.dataset.name;
        if (!emojiAlias) return;
        node.outerText = emojis[emojisByAliases[emojiAlias]].emoji;
      });
      body = container.innerHTML;
    }
    return body;
  },
  parsePrefixedId(prefixedId: string): { prefix: IdPrefix, id: string } {
    if (typeof prefixedId !== 'string') return { prefix: IdPrefix.NS, id: prefixedId };
    const firstIndex = prefixedId.indexOf('-');
    if (firstIndex == -1) return { prefix: IdPrefix.BR, id: prefixedId };
    const prefixStr = prefixedId.substring(0, firstIndex).toUpperCase();
    let prefix = IdPrefix.NS;
    if (prefixStr == 'BR') prefix = IdPrefix.BR;
    else if (prefixStr == 'EX') prefix = IdPrefix.EX;
    else if (prefixStr == 'TY') prefix = IdPrefix.TY;
    const id = prefixedId.substring(firstIndex + 1);
    return { prefix, id };
  },
  isIdPrefixValid(prefixedId: string, prefixes: IdPrefix[]): boolean {
    const { prefix, id } = this.parsePrefixedId(prefixedId);
    //check to make sure BR prefixes are valid uuids
    if (prefix == IdPrefix.BR && prefixes.includes(IdPrefix.BR) && !this.isUuidValid(id)) return false;
    return prefixes.includes(prefix);
  },
  isUuidValid(uuid: string): boolean {
    return !!uuid.match(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i)?.length;
  },
  uuidv4(): string {
    return v4();
  },
  uuidv4Code(): string {
    return this.uuidv4().replace(/-/g, '');
  },
  genHexString(length: number): string {
    let result = '';
    const chars = '0123456789abcdef';
    const charCount = chars.length;
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * charCount));
    }
    return result;
  },
  getKeys<T>(obj: Record<keyof T, any>): (keyof T)[] {
    return Object.keys(obj) as (keyof T)[];
  },
  parseTraceparent(traceparent: string): Traceparent {
    const parts = traceparent.split('-');
    return {
      version: parts[0],
      traceId: parts[1],
      parentId: parts[2],
      traceFlags: parts[3]
    }
  },
  parseTracestate(tracestate: string): Tracestate {
    return tracestate.split(',').map(keyValue => {
      const parts = keyValue.trim().split('=');
      return { key: parts[0], value: parts[1] };
    });
  }
}
