import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Utils } from '../../shared/utils/utils';
import { loadTranslations } from '@angular/localize';
import { getTranslationsByUrl } from '@soluling/angular';
import "@angular/localize/init";
declare const __CONTENT_HASH__: string;
declare const __FLIGHT__: string;

let locale = Utils.getQueryParam('locale') ?? 'en';
if (locale != 'en' && locale != 'es') locale = 'en';

const win: any = window || {}
if (win['__env']['production']) {
  enableProdMode();
}

const localeUrl = `/assets/localization/${locale}.json?v=${(__CONTENT_HASH__)}`;
getTranslationsByUrl(localeUrl, locale, true).then(translations => {
  if (translations) {
    const env_title = __FLIGHT__ ? 'Flight' : 'Writable';
    Object.keys(translations).forEach(key => {
      translations[key] = translations[key].replace('{ENV_APP_TITLE}', env_title);
    });
    loadTranslations(translations);
  }
}).finally(() => {
  import("./app/app.module").then(module => {
    platformBrowserDynamic()
      .bootstrapModule(module.AppModule)
      .catch(err => console.error(err));
  });
});
